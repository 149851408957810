import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Bartlett & Grippe | New Haven County Personal Injury Law Firm",
    heroH1: "",
    heroH2: "When experience, integrity, and results matter.",
    heroImage: "photo-main-street.jpg",
    heroImageAlt: "Road through a forest",
    description:
      "Need compensation for your serious injury or wrongful death claim? Bartlett & Grippe, LLC's trial lawyers have 100+ years of experience nationwide. Contact us now.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div className="bg-gray-900 w-full">
        <div className="container mx-auto flex flex-wrap p-4">
          <div className="w-full sm:w-1/4 p-4 text-center border-green-400 border-b sm:border-b-0">
            <svg
              fill="white"
              className="inline mb-2 text-center w-12 h-12"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 490.999 490.999"
            >
              <style>enable-background:new 0 0 490.999 490.999;</style>
              <g>
                <g>
                  <path d="M475.91,88.718c-30.2-57.3-143.9-126.9-230.4-57.3c-90.3-70.4-201.2,0-230.4,57.3c-17.7,34.4-29.2,94.9,20.8,181.4c66.7,117.8,191.8,210.6,197,214.8c7.4,5.4,15.7,5.7,25,0c5.2-4.2,130.3-97,197-214.8C505.11,183.618,493.61,123.118,475.91,88.718z M419.61,249.318c-51.1,89.6-141.8,166.8-174.1,192.9c-33.1-25.9-121.7-102.1-173-191h72.9c7.3,0,13.6-3.1,17.7-9.4l31.3-50l40.7,108.4c6.1,14.1,23.2,19.6,36.5,5.2l46.9-64.6h81.3c11.5,0,20.9-9.4,20.9-20.9s-9.4-20.9-20.9-20.9h-91.7c-6.3,0-12.5,3.1-16.7,8.3l-31.3,42.7l-41.7-111.6c-1.3-7.2-20.5-24.3-36.5-4.2l-46.9,75.1h-82.5c-15.3-40.1-15.9-74.6-0.9-103.1c21.9-42.5,108.2-101.9,182.4-34.1c7.3,5.2,16.7,5.2,24,0c73-66.3,159.5-8.3,181.4,34.4C459.21,144.018,451.91,191.918,419.61,249.318z" />
                </g>
              </g>
            </svg>
            <h3 className="font-medium text-white uppercase">Wrongful Death</h3>
            <Link to="/ct-wrongful-death/">
              <button className="p-2 mx-auto bg-sky-600 text-white rounded">
                Read More
              </button>
            </Link>
          </div>
          <div className="w-full sm:w-1/4 p-4 text-center border-green-400 border-b sm:border-b-0">
            <svg
              fill="white"
              className="inline mb-2 text-center w-12 h-12 text-white"
              xmlns="https://www.w3.org/2000/svg"
              viewBox="0 0 87.7 100"
            >
              <g id="9f6a4f32-1d47-4906-bace-92b2b960605c" data-name="Layer 2">
                <g
                  id="f6f97071-c329-45aa-bd91-b563ad5cd41b"
                  data-name="BLACK_SOLID"
                >
                  <path d="M32.11,93.22a25.33,25.33,0,0,1-14.67-46V39.34A32.11,32.11,0,1,0,62.85,77.17l-5.42-9.06A25.33,25.33,0,0,1,32.11,93.22Zm55-22.66a3.8,3.8,0,0,0-5.25-1.09L76.61,72.6,64.3,52.11a5.86,5.86,0,0,0-3.24-2.92,5.87,5.87,0,0,0-2-.37H35.66V42.69H53.86a3.79,3.79,0,0,0,0-7.59H35.66V25.48a6.82,6.82,0,0,0-6.82-6.83H27.53a6.83,6.83,0,0,0-6.82,6.83v28.3a6.82,6.82,0,0,0,6.82,6.82H58.3L72.36,84l4.79-2.87L86,75.81A3.78,3.78,0,0,0,87.08,70.56ZM27.8,17.1a8.55,8.55,0,1,0-8.55-8.55A8.55,8.55,0,0,0,27.8,17.1Z"></path>
                </g>
              </g>
            </svg>
            <h3 className="font-medium text-white uppercase">
              Personal Injury
            </h3>
            <Link to="/firm-overview/practice-areas/personal-injury/">
              <button className="p-2 mx-auto bg-sky-600 text-white rounded">
                Read More
              </button>
            </Link>
          </div>
          <div className="w-full sm:w-1/4 p-4 text-center border-green-400 border-b sm:border-b-0">
            <svg
              fill="none"
              className="text-white inline mb-2 text-center w-12 h-12"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
              />
            </svg>
            <h3 className="font-medium text-white uppercase">
              DUI & Criminal Defense
            </h3>
            <Link to="/dui-and-criminal-defense/">
              <button className="p-2 mx-auto bg-sky-600 text-white rounded">
                Read More
              </button>
            </Link>
          </div>
          <div className="w-full sm:w-1/4 p-4 text-center">
            <svg
              fill="white"
              className="inline mb-2 text-center w-12 h-12"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              enable-background="new 0 0 512 512"
            >
              <path d="m502.625,121.375l-112-112c-6-6-14.141-9.375-22.625-9.375h-224c-8.484,0-16.625,3.375-22.625,9.375l-112,112c-6,6-9.375,14.141-9.375,22.625v224c0,8.484 3.375,16.625 9.375,22.625l112,112c6,6 14.141,9.375 22.625,9.375h224c8.484,0 16.625-3.375 22.625-9.375l112-112c6-6 9.375-14.141 9.375-22.625v-224c0-8.484-3.375-16.625-9.375-22.625zm-134.625,118.621v112c0,35.348-34.262,64.004-69.609,64.004h-46.555c-30.305,0-58.004-17.121-71.555-44.225l-9.891-19.778-40.824-95.258c-4.027-9.402-0.031-20.313 9.113-24.887 8.91-4.453 22.547-1.406 27.824,7.039l25.497,36.304v-35.199-128c0-8.836 7.164-16 16-16s16,7.164 16,16v120c0,4.418 3.582,8 8,8s8-3.582 8-8v-152c0-8.836 7.164-16 16-16s16,7.164 16,16v152c0,4.418 3.582,8 8,8s8-3.582 8-8v-120c0-8.836 7.164-16 16-16s16,7.164 16,16v128 8c0,4.418 3.582,8 8,8s8-3.582 8-8v-8-80c0-8.836 7.164-16 16-16s16,7.164 16,16v80z" />
            </svg>
            <h3 className="font-medium text-white uppercase">Sexual Abuse</h3>
            <Link to="/ct-sex-abuse-attorney/">
              <button className="p-2 mx-auto bg-sky-600 text-white rounded">
                Read More
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h1 className="homeh1 uppercase">Bartlett & Grippe, LLC</h1>
          </div>
          <div className="mb-16">
            <h2>YOUR TOP RATED SERIOUS INJURY LAW FIRM</h2>
            <p className="mb-8">
              Serious injuries require experienced personal injury attorneys. At
              Bartlett &amp; Grippe, LLC, we have successfully handled many
              significant personal injury cases and wrongful death claims for
              clients and attorneys across the country. Let us leverage our over
              100 years of combined legal experience for you. We are trial
              lawyers and prepare every case as if it will go to trial. This
              preparation helps us maximize your potential outcome.
            </p>
          </div>
          <h2>AS YOUR LEGAL TEAM, WE PROMISE:</h2>
          <ul className="list-disc list-outside ml-6 mb-8">
            <li>
              We will fight to protect the rights and interests of you and your
              family.
            </li>
            <li>
              We will take your case to trial if needed to pursue the best
              outcome for you emotionally and financially.
            </li>
            <li>
              With experience, dedication, and compassion we will work to
              provide you closure and justice.
            </li>
            <li>
              No cost to you if we don’t settle your personal injury case on
              your behalf or win at trial.
            </li>
          </ul>

          <div className="mb-16">
            <h2 className="uppercase">Helping you navigate the legal system</h2>
            <p className="mb-8">
              From the moment we take on new clients, we also take on their
              priorities, and we treat their legal matters with the attention
              they deserve. When you become a client of Bartlett &amp; Grippe
              LLC, you can expect:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8 space-y-2">
              <li>
                <strong>Effective negotiation</strong> — In many cases,
                negotiating a settlement is the fastest and best option for both
                parties. As experienced settlement negotiators, we will explore
                all options before proceeding to trial.
              </li>
              <li>
                <strong>Powerful trial advocacy</strong> — In the event that
                your case goes to trial, our years of courtroom experience and
                innovative legal thinking can help you secure a positive
                outcome.
              </li>
              <li>
                <strong>Respected representation</strong> — Our attorneys have
                extensive experience, and Partner Frank C. Bartlett, Jr. has
                earned an AV® Preeminent™ Peer Review Rating by
                Martindale-Hubbell® for his high ethical standards and
                professional ability. We are also active members of our
                community.
              </li>
            </ul>
            <p className="mb-8">
              Our 24-hour availability means that you can get started with your
              case any time, day or night.
            </p>
          </div>

          <div className="my-16">
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>

          <div className="mb-16">
            <h2 className="uppercase">
              Providing representation for complex cases
            </h2>
            <p className="mb-8">
              Bartlett & Grippe, LLC represents clients in a wide range of
              cases, including those involving:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8 space-y-2">
              <li>
                <strong>Personal injuries</strong> — We represent people in all
                aspects of personal injury including car and motorcycle
                accidents, dog bites, slip and falls, products liability,
                traumatic brain injuries, and wrongful death.
              </li>
              <li>
                <strong>Survivors of Sexual Abuse</strong> — Our attorneys are
                experienced in handling claims on behalf of survivors of
                childhood sexual abuse. We are one of the few firms in the state
                who have successfully tried claims of childhood sexual abuse to
                verdict.
              </li>
              <li>
                <strong>Medical malpractice</strong> — Health care providers
                have a professional standard of care to which they must adhere.
                If you have been the victim of medical malpractice, our firm can
                help you obtain the recovery you deserve.
              </li>
            </ul>
            <p className="mb-8">
              Your personal injury claim may have a significant value, so you
              can’t afford to have mediocre representation. Our determined
              advocates will steadfastly represent your best interests right
              from the start.
            </p>
            <p className="mb-8">
              In addition to personal injury cases, we can help you with probate
              and estate administration and wills.
            </p>
          </div>

          <h2 className="text-center">
            OUR FIRM IS PROUD TO HAVE SECURED OVER{" "}
            <span className="text-green-400">$35,000,000</span> FOR OUR CLIENTS
            SINCE 2012
          </h2>
          <div className="section flex flex-wrap text-center results-list">
            <div className="w-full xl:w-1/4">
              <AnchorLink
                className="text-white"
                to="/firm-overview/cases-results/#jr-v-bsa"
              >
                <div className=" results-list-item-dark">
                  <h2 className="uppercase">$1,500,000</h2>
                  <h3>Verdict : John Roe #1 v. Boy Scouts of America, et al</h3>
                  <div>Child Sexual Abuse</div>
                </div>
              </AnchorLink>
            </div>

            <div className="w-full xl:w-1/4">
              <AnchorLink
                className="text-white"
                to="/firm-overview/cases-results/#b-v-ja"
              >
                <div className=" results-list-item-dark">
                  <h2 className="uppercase">$1,200,000</h2>
                  <h3>Settlement : Buchanon v. J &amp; A Equities</h3>
                  <div>Personal Injury</div>
                </div>
              </AnchorLink>
            </div>
            <div className="w-full xl:w-1/4">
              <AnchorLink
                className="text-white"
                to="/firm-overview/cases-results/#da-v-jf"
              >
                <div className=" results-list-item-dark">
                  <h2 className="uppercase">$3,188,800</h2>
                  <h3>Verdict : Dawn Andalora v. Joseph Falanga</h3>
                  <div>Child Sexual Abuse</div>
                </div>
              </AnchorLink>
            </div>
            <div className="w-full xl:w-1/4">
              <div className=" results-list-item-dark">
                <h2 className="uppercase">$1,500,000</h2>
                <h3>John Doe PPA v. Guay Roger</h3>
                <div>Child Sexual abuse</div>
              </div>
            </div>
          </div>
          <p className="mb-8 text-center">
            The team at Bartlett & Grippe is very proud to have secured{" "}
            <Link to="/firm-overview/cases-results/">
              these results and many more
            </Link>{" "}
            on behalf of our clients.
          </p>
          <Link to="/firm-overview/client-reviews/">
            <h2 className="text-center">CLIENT REVIEWS</h2>
          </Link>
          <div className="mb-8 text-center">
            <div className="index-reviews index-review text-left">
              <h2 className="uppercase">Arleen J. Madison, CT 06443</h2>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 inline"
                  viewBox="0 0 20 20"
                  fill="gold"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 inline"
                  viewBox="0 0 20 20"
                  fill="gold"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 inline"
                  viewBox="0 0 20 20"
                  fill="gold"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 inline"
                  viewBox="0 0 20 20"
                  fill="gold"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 inline"
                  viewBox="0 0 20 20"
                  fill="gold"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
              </div>
              <h3 className="text-2xl uppercase">Excellent Attorney</h3>
              <div>
                Attorney Bartlett took the time to answer every question we had.
                Sometimes we didn’t hear back from him until the next day but he
                always returned our calls. My husband normally hates lawyers but
                he totally respected Frank. Frank treated us like family. We
                couldn’t be more pleased with the results. He’s a rare lawyer. I
                highly recommend.
              </div>
            </div>
          </div>
          <p className="mb-8 text-center">
            Read more <Link to="/firm-overview/client-reviews/">reviews</Link>
          </p>
          <div className="my-16">
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
          <h2 className="text-center mb-8">
            CENTRALLY LOCATED IN CHESHIRE, CONNECTICUT
          </h2>
          <Link to="/firm-overview/directions">
            <div className="flex flex-wrap text-center">
              <div className="w-full sm:w-1/2 mb-8">
                <h3>143 Main Street</h3>
                <StaticImage
                  className="rounded-2xl mr-2"
                  width={"450"}
                  quality="100"
                  src="../images/photo-main-street.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt="Photo of Bartlett & Grippe Office on Main Street in Cheshire, Connecticut"
                  imgStyle={{
                    borderTopLeftRadius: "1rem",
                    borderBottomLeftRadius: "1rem",
                    borderBottomRightRadius: "1rem",
                    borderTopRightRadius: "1rem",
                  }}
                />
              </div>
              <div className="w-full sm:w-1/2">
                <h3>36 Wallingford Road</h3>
                <StaticImage
                  className="rounded-2xl ml-2"
                  width={"450"}
                  quality="100"
                  src="../images/photo-wallingford-road.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt="Photo of Bartlett & Grippe Office on Wallingford Road in Cheshire, Connecticut"
                  imgStyle={{
                    borderTopLeftRadius: "1rem",
                    borderBottomLeftRadius: "1rem",
                    borderBottomRightRadius: "1rem",
                    borderTopRightRadius: "1rem",
                  }}
                />
              </div>
            </div>
          </Link>
          <p className="text-center my-8">
            <Link to="/firm-overview/directions/">
              Handicapped parking available to the right of our building on 36
              Wallingford Road with additional parking behind the building via
              Elm St.
            </Link>
          </p>
          <p className="text-center">Office Hours: 8:30 AM to 5:30 PM</p>
          <p className="text-center mb-8">Urgent Calls 24×7</p>
        </div>
      </div>
    </Layout>
  )
}

export default Page
